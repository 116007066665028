// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./direct_uploads"
import * as bootstrap from "bootstrap"
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

window.addEventListener("turbo:load", () => {
  // popovers
  let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })

  // toasts
  var toastElList = [].slice.call(document.querySelectorAll('.toast'))
  var toastList = toastElList.map(function (toastEl) {
    return new bootstrap.Toast(toastEl, option)
  })

  // dismiss alerts
  Array.from(document.querySelectorAll('button[data-dismiss=alert]')).forEach((element) => {
    element.addEventListener('click', () => {
      const alert = element.closest('.alert')
      alert.parentNode.removeChild(alert)
    })
  })

  // welcome modal
  const welcomeElement = document.getElementById("welcomeModal")
  if (welcomeElement) {
    const welcomeModal = new bootstrap.Modal(welcomeElement)
    welcomeModal.show()
  }
})
