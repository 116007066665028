addEventListener("direct-upload:initialize", event => {
  const { target, detail } = event
  const { id, file } = detail
  target.insertAdjacentHTML("afterend", `
    <div id="direct-upload-${id}" class="my-3 direct-upload progress" style="height: 5px;">
      <div id="direct-upload-progress-${id}" class="progress-bar" style="width: 0%" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  `)
})

addEventListener("direct-upload:start", event => {
  // const { id } = event.detail
  // const element = document.getElementById(`direct-upload-${id}`)
})

addEventListener("direct-upload:progress", event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
  progressElement.ariaValueNow = progress
})

addEventListener("direct-upload:error", event => {
  event.preventDefault()
  const { id, error } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.classList.add("bg-danger")
  progressElement.setAttribute("title", error)
})

addEventListener("direct-upload:end", event => {
  // const { id } = event.detail
  // const element = document.getElementById(`direct-upload-${id}`)
})
